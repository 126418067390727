import AWN from "awesome-notifications";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { a2hex } from "../../common.functions";

let notifier = new AWN();

const pStyle = {
  paddingRight: "5px",
  padding: 0,
  margin: 0,
};

const wrapper = {
  display: "flex",
  alignItems: "center",
};

const CreateForm = () => {
  const [name, setName] = useState(null);
  const [whatTheyDid, setWhatTheyDid] = useState(null);
  const [gender, setGender] = useState("Female");
  const [betterReason, setBetterReason] = useState("");
  const [url, setUrl] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const nameHex = a2hex(name);
    const whatTheyDidHex = a2hex(whatTheyDid);
    const betterReasonHex = a2hex(betterReason);
    const urlToDisplay = `${window.location.origin}/${nameHex}-${whatTheyDidHex}-${betterReasonHex}`;
    setUrl(urlToDisplay);
  };

  const Copy = (e) => {
    var newUrl = document.getElementById("url");
    newUrl.innerHTML = url;
    newUrl.select();
    document.execCommand("copy");
    notifier.success("Link copied", { durations: { success: 0 } });
  };

  return (
    <div>
      <h4>Create a custom message</h4>
      <Form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="wrapper_form_element" style={wrapper}>
          <p style={pStyle}>Seriously,</p>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: "300px" }}
            type="name"
            required
            placeholder="Name"
          />
          <br />
        </div>
        <div className="wrapper_form_element" style={wrapper}>
          <p style={pStyle}>How fucking dare you</p>
          <Form.Control
            value={whatTheyDid}
            style={{ width: window.mobilecheck() ? "300px" : "400px" }}
            onChange={(e) => setWhatTheyDid(e.target.value)}
            type="text"
            required
            placeholder="talk to me that way"
          />
          <br />
        </div>
        <div className="wrapper_form_element" style={wrapper}>
          <p style={pStyle}>You think you're all that because</p>
          <Form.Control
            value={betterReason}
            style={{ width: window.mobilecheck() ? "300px" : "400px" }}
            onChange={(e) => setBetterReason(e.target.value)}
            type="text"
            required
            placeholder="you got your doctorate in astrophysics"
          />
        </div>
        <Button type="submit">generate</Button>
      </Form>
      {url && <p>(send this link)</p>}
      {url && (
        <div className="copy" onClick={Copy}>
          copy link
        </div>
      )}
      <a href={url}>{url}</a>
      <textarea
        style={{ position: "absolute", top: "-200px" }}
        id="url"
      ></textarea>
    </div>
  );
};

export default CreateForm;
