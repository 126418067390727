import React, { Fragment, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Hyphenated from "react-hyphen";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import { Textfit } from "react-textfit";
import Fingers from "../assets/fingersBright.png";
import Five from "../assets/five.png";
import Four from "../assets/four.png";
import Last from "../assets/last.png";
import One from "../assets/one.png";
import SpeechOne from "../assets/speechOne.png";
import SpeechTwo from "../assets/speechTwo.png";
import Three from "../assets/three.png";
import Two from "../assets/two.png";
import { h2ascii } from "../common.functions";

const images = [One, Two, Three, Four, Five];

const HowDareYou = (props) => {
  const history = useHistory();
  const [name, setName] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allThat, setAllThat] = useState(null);
  const [adjustedText, setAdjustedText] = useState(true);
  const [displayBubble, setDisplayBubble] = useState(true);
  const [interval, setInterval] = useState(3000);
  const [currentImage, setCurrentImage] = useState(One);
  const [currentBubbleText, setCurrentBubbleText] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [newTop, setNewTop] = useState(0);
  const [newLeft, setNewLeft] = useState(0);
  const [imgHeight, setImgHeight] = useState("300px");
  const [imageLoading, setImageLoading] = useState(false);

  const lastOne = () => {
    setTimeout(function () {
      setCurrentImage(Last);
      setCurrentBubbleText("");
      setDisplayBubble(false);
    }, 2000);
  };

  const silence = () => {
    setTimeout(function () {
      // setCurrentImage(Last);
      setCurrentBubbleText(`...`);
      lastOne();
    }, 2000);
  };

  const youAreNot = () => {
    setTimeout(function () {
      setCurrentImage(images[4]);
      setCurrentBubbleText(`You're not.`);
      silence();
    }, 2000);
  };

  const well = () => {
    setTimeout(function () {
      setCurrentImage(images[3]);
      setCurrentBubbleText(`Well...`);
      setAdjustedText(false);
      youAreNot();
    }, 3000);
  };

  const settingAllThat = (newAllThat) => {
    setTimeout(function () {
      setCurrentImage(images[2]);
      setCurrentBubbleText(
        `You think you're all that becuase ${newAllThat.toLowerCase()}`
      );
      setNewTop("-2px");
      setImgHeight("326px");
      well();
    }, 3000);
  };

  const setItems = () => {
    const pathnameValues = props.location.pathname.substring(1).split("-");
    if (!pathnameValues[0] || !pathnameValues[1] || !pathnameValues[2]) {
      history.push("/create");
      return;
    }
    const newName = h2ascii(pathnameValues[0]);
    const newMessage = h2ascii(pathnameValues[1]);
    const newAllThat = h2ascii(pathnameValues[2]);
    setName(newName);
    setMessage(newMessage);
    setAllThat(newAllThat);
    setCurrentBubbleText(`Seriously, ${newName}?`);
    setTimeout(function () {
      setImageLoading(true);
      // setCurrentImage(images[1]);
      setCurrentBubbleText(`How fucking dare you ${newMessage.toLowerCase()}`);
      setNewLeft("-30px");
      setNewTop("-2px");
      setImgHeight("326px");
      setCurrentImage(Two);
      settingAllThat(newAllThat);
    }, 2000);
  };

  const handleLoad = () => {
    setLoading(false);
    setItems();
  };

  useEffect(() => {
    handleLoad();
  }, []);

  // useEffect(() => {
  //   // changeImages();
  //   setItems();
  // }, []);

  const changeSlide = (e, number) => {
    e.preventDefault();
    setCurrentSlide(number);
  };

  if (name && message && allThat)
    return (
      <Fragment>
        {/* {loading && <div id="loading"></div>} */}
        <Container
          fluid={true}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${Fingers})`,
            height: "100vh",
            paddingTop: "15vh",
          }}
          className="how_dare_you_wrapper"
        >
          <Row>
            {/* <Carousel
            className="carousel"
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            swipeable={false}
            selectedItem={currentSlide}
          > */}
            <div>
              <div>
                <div
                  style={{
                    position: "relative",
                    top: window.mobilecheck() ? "-25px" : "0",
                    height: "200px",
                    left: window.mobilecheck() ? "10px" : "172px",
                  }}
                >
                  <div
                    id="fitin"
                    style={{
                      position: "absolute",
                      top: "17px",
                      zIndex: 10,
                      left: "11px",
                    }}
                  >
                    {adjustedText && (
                      <Textfit
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        mode="multi"
                      >
                        <Hyphenated>{currentBubbleText}</Hyphenated>
                      </Textfit>
                    )}
                    {!adjustedText && (
                      <h4
                        style={{
                          height: "100%",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {currentBubbleText}
                      </h4>
                    )}
                  </div>
                  <div
                    style={{
                      height: "200px",
                      width: "350px",
                      position: "absolute",
                    }}
                  >
                    {displayBubble && (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={window.mobilecheck() ? SpeechTwo : SpeechOne}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              <h1>How fucking dare you {message.toLowerCase()}</h1>
              <button onClick={e => changeSlide(e, 2)}>oh?</button>
            </div>
            <div>
              <h1>
                You think you're all that because you {allThat.toLowerCase()}
              </h1>
              <button onClick={e => changeSlide(e, 3)}>I do</button>
            </div>
            <div>
              <h1>Well,</h1>
              <button onClick={e => changeSlide(e, 4)}>...</button>
            </div>
            <div>
              <h1>You're not</h1>
            </div>
          </Carousel> */}
          </Row>
          <Row
            style={{
              position: "relative",
              top: "200px",
              // width: "100vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "400px",
                height: imgHeight,
                position: "relative",
                top: newTop,
                left: 0,
              }}
            >
              <img style={{ height: "100%" }} src={currentImage} />
            </div>
          </Row>
        </Container>
      </Fragment>
    );

  return <div></div>;
};

export default HowDareYou;
